/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          $('.slider-testimonials').not('.slick-initialized').slick({
              dots: true,
              arrows: false,
              infinite: true,
              slidesToShow: 1,
              speed: 500,
              fade: true,
              cssEase: 'linear'
          });


		  if( $('.slider-interested').length > 0 ) {

			  $('.slider-interested').slick({
				  dots: false,
				  arrows: true,
				  infinite: true,
				  slidesToShow: 3,
				  slidesToScroll: 1,
				  prevArrow:"<button type='button' data-role='none' class='slick-prev slick-arrow pull-left'><span class='sr-only' aria-hidden='true'>prev</span></button>",
				  nextArrow:"<button type='button' data-role='none' class='slick-next slick-arrow pull-right'><span class='sr-only' aria-hidden='true'>next</span></button>",
				  responsive: [
					  {
						  breakpoint: 992,
						  settings: {
							  slidesToShow: 2
						  }
					  },
					  {
						  breakpoint: 768,
						  settings: {
							  slidesToShow: 1
						  }
					  }
				  ]
			  });

			  var itemTop = function() {
				  $imgHeight = $('.item-tec .wrap-img img').height();
				  $varTop = (($imgHeight / 2) - 25) + 'px';
				  //console.log("img height " + $imgHeight + " " + $varTop);
				  $('.slider-interested button').css('top', $varTop);
			  }
			  setTimeout(itemTop, 2000);

		  }


		  $('.slider-cursos').slick({
			  dots: false,
			  arrows: true,
			  infinite: true,
			  speed: 300,
			  autoplay: true,
			  slidesToShow: 4,
			  slidesToScroll: 1,
			  prevArrow:"<button type='button' data-role='none' class='slick-prev slick-arrow pull-left'><span class='sr-only' aria-hidden='true'>prev</span></button>",
			  nextArrow:"<button type='button' data-role='none' class='slick-next slick-arrow pull-right'><span class='sr-only' aria-hidden='true'>next</span></button>",
			  responsive: [
				  {
					  breakpoint: 1150,
					  settings: {
						  slidesToShow: 4,
						  slidesToScroll: 1,
						  infinite: true

					  }
				  },
				  {
					  breakpoint: 992,
					  settings: {
						  slidesToShow: 3,
						  slidesToScroll: 1
					  }
				  },
				  {
					  breakpoint: 830,
					  settings: {
						  slidesToShow: 2,
						  slidesToScroll: 1
					  }
				  },
				  {
					  breakpoint: 600,
					  settings: {
						  slidesToShow: 1,
						  slidesToScroll: 1
					  }
				  }
				  // You can unslick at a given breakpoint now by adding:
				  // settings: "unslick"
				  // instead of a settings object
			  ]
		  });



		  if($('.page-template-tpl-training-bible').length > 0 ) {
			  $('.slider-courses').not('.slick-initialized').slick({
				  infinite: true,
                  arrows: true,
                  dots: false,
				  slidesToShow: 1,
				  speed: 500,
				  fade: true,
				  cssEase: 'linear'
			  });
			  $( ".more-courses" ).click(function(e) {
				  $('.slider-courses').slick('slickNext');
                  e.preventDefault();
			  });

            $('.slider-exams').not('.slick-initialized').slick({
				  infinite: true,
                  arrows: true,
                  dots: false,
				  slidesToShow: 1,
				  speed: 500,
				  fade: true,
				  cssEase: 'linear'
			  });
			  $( ".more-exams" ).click(function() {
				  $('.slider-exams').slick('slickNext');
			  });


            if( $('.auxiliar').length > 0 ) {

                $input_busqueda = $('.input-search');

                function buscarCursos(cerca, tipus) {

                    if(tipus == 'curs') {
                        var topTipus = $('#cursos')
                    } else {
                        var topTipus = $('#examenes')
                    }

                    $tabla_resultados = $('.resultados-cursos tbody', topTipus);
                    $trs = $('.slider-both tr', topTipus);

                    $('.resultados tbody', topTipus).empty();
                    $('.slider-both', topTipus).hide();
                    $('.btn-more-both', topTipus).hide();
                    $('.resultados', topTipus).show();
                    $('.text-searched span.text', topTipus).empty();
                    $('.text-searched span.text', topTipus).append(cerca);
                    $('.text-searched', topTipus).show();
                    $trs.each(function() {
                        var titol = $(this).data('title');
                        if(titol != null) {
                            if(titol.toLowerCase().indexOf(cerca.toLowerCase()) !== -1) {
                                $tabla_resultados.append($(this).clone());
                            } else {
                                console.log("no s'ha trobat cap registrew");
                            }
                        }
                    });
                    $($input_busqueda, topTipus).val('');
                }

                $($input_busqueda).keydown(function(e) {

                    if(e.keyCode === 13) {

                        if($.trim($(this).val()).length > 0 ){

                            if($(this).hasClass('search-courses')) {

                                $('#cursos .close-cross').addClass('close-curs');
                                buscarCursos($(this).val(), 'curs' );

                            } else {

                                $('#examenes .close-cross').addClass('close-exam');
                                buscarCursos($(this).val(), 'exam' );

                            }

                        } else {

                            console.log("no hi ha res a l'intpu");

                        }

                    }
                });

                $('.close-cross').click(function (){
                    if($(this).hasClass('close-curs')) {
                        $('#cursos .slider-both').show();
                        $('#cursos .btn-more-both').show();
                        $('#cursos .resultados').hide();
                    } else {
                        $('#examenes .slider-both').show();
                        $('#examenes .btn-more-both').show();
                        $('#examenes .resultados').hide();
                    }
                });



            }


		  }




		  /* per saber si hi ha submenu */
		  if( $('nav.sub-menu .menu').length > 0 ) {
			  $('nav.sub-menu').addClass('has-menu') ;
		  };


          /* sección tecnologias */
          $(window).resize(function(){
              resize();
          });
          $(document).ready(function () {
              resize();
          });
          function resize(){
              if($(window).width() < 992)
              {
                  $("#grid-images").insertAfter("#container-text");
              }
          }
          // Select all links with hashes
          $('a[href*="#"]')
              // Remove links that don't actually link to anything
              .not('[href="#"]')
              .not('[href="#0"]')
              .click(function(event) {

                  // On-page links
                  if (
                      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                      &&
                      location.hostname == this.hostname
                  ) {
                      // Figure out element to scroll to
                      var target = $(this.hash);
                      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                      // Does a scroll target exist?
                      if (target.length) {
                          // Only prevent default if animation is actually gonna happen
                          event.preventDefault();
                          $('html, body').animate({
                              scrollTop: target.offset().top
                          }, 1000, function() {
                              // Callback after animation
                              // Must change focus!
                              var $target = $(target);
                              $target.focus();
                              if ($target.is(":focus")) { // Checking if the target was focused
                                  return false;
                              } else {
                                  $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                                  $target.focus(); // Set focus again
                              }
                          });
                      }
                  }
              });
          $('.back-top').on('click', function(e){
              e.preventDefault();
              $('html, body').stop().animate({
                  scrollTop: 0
              }, 1000);
          });

          // ToDo: pendent a posar només per la pàgina clientes/success-stories es i en
          // Aquí naveguem pels clients amb les fletxes interiors
          var $articles = $(".wrapper-clients").children("article"),
              index = 0, n = 0;


		  $('#fortalezas li .btn-open').on('click', function(e) {
			  e.preventDefault();
			  $(this).prev('.moretext').toggleClass('opened');

		  });


          // ToDo: pendent a posar només per la pàgina clientes/success-stories es i en
          // Aquí canviem de contingut en fer click als logos dels clients
          $('.clients-list li').on('click', function(e) {
              e.preventDefault();
              $('.current').removeClass('current');
              $(this).addClass('current');
              var colid = '#' + $(this).attr('col-id');
              $(colid).addClass('current');
              n = parseInt($(this).attr('idx'));
              if(n == $articles.length - 1) {
                  $('#next').addClass('disabled');
                  $('#prev').removeClass('disabled');
              } else if(n === 0) {
                  $('#prev').addClass('disabled');
                  $('#next').removeClass('disabled');
              } else {
                  $('#next').removeClass('disabled');
                  $('#prev').removeClass('disabled');
              }
                index = n;
          });

          $("#next").click(function () {
              updateStatus(1);
          });

          $("#prev").click(function () {
              updateStatus(-1);
          });
          function updateStatus(a) {
              $articles.eq(index).removeClass("current");
              index += a;
              $articles.eq(index).addClass("current");
              var colidList = $articles.eq(index).attr('id');
              $('.clients-list li.current').removeClass('current');
              $('.clients-list li[idx="'+index+'"]').addClass('current');
              if(index == $articles.length - 1) {
                  $('#next').addClass('disabled');
                  $('#prev').removeClass('disabled');
              }
              if(index === 0) {
                  $('#prev').addClass('disabled');
                  $('#next').removeClass('disabled');
              }
          };

                // pàgina cursos obrir modal amb informció
        if($('.page-template-tpl-training-bible').length > 0 ) {
            $('.btn-booking').on('click', function(e) {
                e.preventDefault();
                var $fila = $(this).closest('tr');
                var $celdaCurso = $(this).parent().siblings("#date-info");
                var fechaCurso = $celdaCurso.data('dates');

                var nombreCurso = $fila.data('title');
                var $inputNombreCurso = $(".hidden-field");
                $(".hidden-field-date").val(fechaCurso);
                $inputNombreCurso.val(nombreCurso);
                $(".course-name").text(nombreCurso);
                $(".dates-course").text(fechaCurso);
                console.log("titol " + $fila.data('title') + " fecha " + fechaCurso);

                //$("form").addClass("visible");
            });
        };

          // links des de trainng general a careers han de fer que es mostri el contingut ocult
          //només a
          if($('.page-template-tpl-training-career-paths').length > 0 ) {

                $('.accordion-1 .fletxa').click(function() {
                    console.log("hola");
                    $(this).closest('li').toggleClass('open');
                    //$(this).next('.container-fluid').focus();
              })



			  $.urlParam = function (name) {
				  var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
				  if (results === null) {
					  return null;
				  }
				  else {
					  return decodeURI(results[1]) || 0;
				  }
			  };
			  if ($.urlParam('id') !== 'undefined' && $.urlParam('id') !== '' ) {
				  var urlid = '#' + $.urlParam('id');
                  console.log("url id " + urlid);
				  $(document.body).animate({
					  scrollTop: $(urlid).offset().top
				  }, 1000);
                  $(urlid + ' input').click();
              }
		  };
		  // end links des de training





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
          // select formulari modal nomes si és home https://silviomoreto.github.io/bootstrap-select/
          $(window).scroll(function(){
              if ($(window).scrollTop() > 50)
              {
				/*  $('.sub-hero').animate({
					  'opacity': '0'
				  }, 1000);*/
                  $(".sub-hero").fadeOut('10000');
              }
              else
              {
				/*  $('.sub-hero').animate({
					  'opacity': '1'
				  }, 1000);*/
                  $(".sub-hero").fadeIn('2000');
              }
          });

          if ($('.home').length > 0) {
              $('.home .selectpicker').selectpicker({
                  style: 'btn-select',
                  mobile: false
              });
          }



          $('.slider-clientes').slick({
              dots: false,
              arrows: false,
              infinite: true,
              speed: 300,
              autoplay: true,
              slidesToShow: 5,
              slidesToScroll: 1,
              responsive: [
                  {
                      breakpoint: 1024,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                          infinite: true,
                          dots: true
                      }
                  },
                  {
                      breakpoint: 600,
                      settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1
                      }
                  },
                  {
                      breakpoint: 480,
                      settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1
                      }
                  }
                  // You can unslick at a given breakpoint now by adding:
                  // settings: "unslick"
                  // instead of a settings object
              ]
          });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
         // var subheroHeight = $('.sub-hero').height();
          //console.log(subheroHeight);
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
